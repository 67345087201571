<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script setup></script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, PingFangSC-Medium,
    PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
  font-size: 14px;
}
</style>
