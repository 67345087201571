import { createApp } from 'vue'
import App from './App.vue'
import { Button, NavBar, Tab, Tabs, Popup, Form, Area, Field, List, Icon, Calendar, PullRefresh, Uploader, CellGroup, Checkbox, CheckboxGroup, NumberKeyboard, Image as VanImage, Search, Picker, Sticky, DatetimePicker, Dialog, PasswordInput } from 'vant'
import { router } from '@/router/index'
import 'vant/lib/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import VConsole from 'vconsole'
// import { store, key } from "@/store"
const app = createApp(App)

// if (process.env.NODE_ENV === 'production') {
//   new VConsole()
// }
app.use(Button)
app.use(NavBar)
app.use(List)
app.use(Tab)
app.use(Tabs)
app.use(Icon)
app.use(Popup)
app.use(Picker)
app.use(Form)
app.use(Area)
app.use(Field)
app.use(Search)
app.use(Sticky)
app.use(Calendar)
app.use(VanImage)
app.use(CellGroup)
app.use(PullRefresh)
app.use(Checkbox)
app.use(Uploader)
app.use(CheckboxGroup)
app.use(NumberKeyboard)
app.use(DatetimePicker)
app.use(PasswordInput)
app.use(Dialog)
app.use(router)
app.use(VueAxios, axios)
// app.use(store, key)
app.provide('axios', app.config.globalProperties.axios)
app.mount('#app')
