import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', name: '登录', component: () => import('@/pages/mainIndex.vue') },
  { path: '/personal', name: '个人中心', component: () => import('@/pages/personal/personalIndex.vue') },
  { path: '/personalInfo', name: '编辑信息', component: () => import('@/pages/personal/personalInfo.vue') },
  { path: '/project', name: '我的项目', component: () => import('@/pages/project/projectIndex.vue') },
  { path: '/about', name: '关于脑岛', component: () => import('@/pages/about/aboutIndex.vue') },
  { path: '/introduce', name: '项目介绍', component: () => import('@/pages/project/introduceIndex.vue') },
  { path: '/accInfo', name: '账户信息', component: () => import('@/pages/account/accountInfo.vue') },
  { path: '/accWithdrawal', name: '余额提现', component: () => import('@/pages/account/withdrawalIndex.vue') },
  { path: '/failure', name: '提现失败', component: () => import('@/pages/account/failureIndex.vue') },
  { path: '/accSp', name: '提现', component: () => import('@/pages/account/spIndex.vue') },
  { path: '/answer', name: '答题赚钱', component: () => import('@/pages/account/answerIndex.vue') },
  { path: '/record', name: '账户记录', component: () => import('@/pages/account/recordIndex.vue') },
  { path: '/forget', name: '重置密码', component: () => import('@/pages/forget/forgetIndex.vue') },
  { path: '/register', name: '创建账号', component: () => import('@/pages/register/registerIndex.vue') },
  { path: '/privacy', name: '《隐私保密政策》', component: () => import('@/pages/agreement/privacyIndex.vue') },
  { path: '/userText', name: '《用户服务协议》', component: () => import('@/pages/agreement/userIndex.vue') },
]

// const whiteList = ["/about"];

export const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (typeof (to.name) === 'string') {
    document.title = to.name;
  }
  next();
  // if(whiteList.indexOf(to.path) > -1) {
  //   next();
  // } else {

  // }
});
